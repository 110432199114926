<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar style="--border-width: 0">
        <ion-title color="primary"
          ><h1 class="ion-text-center mb-0">
            SleepStar
          </h1></ion-title
        >
      </ion-toolbar>
    </ion-header>

    <ion-content :scroll-events="true">
      <div class="ion-padding horizontally-centered ion-text-center">
        <ion-text>
          Welcome to SleepStar, a space for families of children with neurodevelopmental conditions
          to find out more about sleep problems, solutions, and resources. To get started find out
          more about a sleep problem by searching below.
        </ion-text>
      </div>

      <ion-list class="ion-padding horizontally-centered">
        <ion-item lines="none">
          <ion-label>
            <ion-button expand="block" size="large" router-link="/problems">
              Problems
            </ion-button>
            <ion-buttons> </ion-buttons>
          </ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-label>
            <ion-button expand="block" size="large" router-link="/solutions">
              Solutions
            </ion-button>
            <ion-buttons> </ion-buttons>
          </ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-label>
            <ion-button expand="block" size="large" router-link="/resources">
              Resources
            </ion-button>
            <ion-buttons> </ion-buttons>
          </ion-label>
        </ion-item>

        <ion-item lines="none">
          <ion-label>
            <ion-button expand="block" size="large" router-link="/profile">
              {{ myChildButtonLabel }}
            </ion-button>
            <ion-buttons> </ion-buttons>
          </ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-label>
            <ion-button expand="block" size="large" router-link="/about">
              About
            </ion-button>
            <ion-buttons> </ion-buttons>
          </ion-label>
        </ion-item>

        <ion-item lines="none">
          <ion-label>
            <ion-button expand="block" size="large" router-link="/sponsors">
              Cerebra
            </ion-button>
            <ion-buttons> </ion-buttons>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';

import {
  IonButton,
  IonButtons,
  IonPage,
  IonText,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonHeader,
  IonToolbar
} from '@ionic/vue';

export default {
  name: 'Home',
  components: {
    IonButton,
    IonButtons,
    IonPage,
    IonText,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonHeader,
    IonToolbar
  },
  setup(): any {
    const store = useStore();

    store.dispatch('user/getDataAPI');

    const children = computed(() => store.getters['user/children']);

    return {
      myChildButtonLabel: computed(() => (children.value.length === 1 ? 'My child' : 'My child'))
    };
  }
};
</script>
