
import { computed } from 'vue';
import { useStore } from 'vuex';

import {
  IonButton,
  IonButtons,
  IonPage,
  IonText,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonHeader,
  IonToolbar
} from '@ionic/vue';

export default {
  name: 'Home',
  components: {
    IonButton,
    IonButtons,
    IonPage,
    IonText,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonHeader,
    IonToolbar
  },
  setup(): any {
    const store = useStore();

    store.dispatch('user/getDataAPI');

    const children = computed(() => store.getters['user/children']);

    return {
      myChildButtonLabel: computed(() => (children.value.length === 1 ? 'My child' : 'My child'))
    };
  }
};
